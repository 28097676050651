<template>
  <div class="indexbox">
    <wallet-head :info="info" />
    <div class="bodybox">
      <van-swipe class="swipebox" autoplay="4000" indicator-color="#24809b">
        <van-swipe-item v-for="(item, index) in banner" :key="index">
          <img :src="item.img_url" alt="">
        </van-swipe-item>
      </van-swipe>
      <div class="newbox">
        <img src="../assets/img/gg@2x.png" class="imgl" alt="" @click="router('new')">
        <van-swipe class="swipe" :autoplay="4000" vertical :show-indicators="false">
          <van-swipe-item v-for="val in notice" :key="val.id"
            @click="$router.push({ name: 'newdel', query: { id: val.id } })">
            <p class="one">{{ val.content_sub }}</p>
          </van-swipe-item>
        </van-swipe>
        <div class="right" @click="router('new')">
          <span style="color: #107DE6">More</span>
          <img src="../assets/img/more@2x.png" alt="" class="">
        </div>
      </div>
      <div class="nums">
        <div @click="router('usdtWallet')">
          <p>USDT</p>
          <p class="one">{{ info.usdt || 0 }}</p>
        </div>
        <div @click="router('jifenWallet')">
          <p>积分</p>
          <p class="one">{{ info.jifen || 0 }}</p>
        </div>
        <div @click="router('longkaWallet')">
          <p>LONGKA</p>
          <p class="one">{{ info.longka || 0 }}</p>
        </div>
      </div>
      <div class="tits">
        <span>LONGKA价格曲线</span>
      </div>
      <!-- 敬请期待～ -->
      <!-- <div class="icce">
        <div class="ice">
        </div>
        <div class="wkf"><img src="~@/assets/img/wkf.png" alt="" /></div>
        <div class="wk">敬请期待～</div>
      </div> -->
     <div class="echartbox">
       <div class="t">
         <span>当前价格=</span><span>{{ cea_price }}USDT</span>
       </div>
       <div class="tab">
         <p :class="{ active: active == 0 }" @click="change(0)">7Day</p>
         <p :class="{ active: active == 1 }" @click="change(1)">30Day</p>
         <p :class="{ active: active == 2 }" @click="change(2)">90Day</p>
       </div>
       <div class="ebox" id="echart"></div>
     </div>
    </div>
  </div>
</template>
<script>
import Web3 from 'web3'
import * as echarts from "echarts"
export default {
  data() {
    return {
      banner: [],
      notice: [],
      info: {},
      active: 0,
      cea_price: 0,
      address: '',
      code: '',
      isToken: false,
    }
  },
  created() {
    if (this.$route.query.code) {
      this.code = this.$route.query.code
    }
    if (this.session.get("token")) {
      this.isToken = true
    }
    this.getdatas()
    this.getuser()
  },
  methods: {
    getdatas() {
      this.$get({
        url: "/banner",
        success: (res) => {
          this.banner = res.data;
        },
        tip: () => { },
      });
      this.$get({
        url: "/notice/list",
        success: (res) => {
          this.notice = res.data.data
        },
        tip: () => { },
      });
    },
    getuser() {
      this.$nextTick(() => {
        if (!this.isToken) {
          this.$toast.loading({
            forbidClick: true,
          })
          var time;
          time = setInterval(() => {
            this.address = this.$store.state.wallet.account;
            if (this.$store.state.wallet.account != "") {
              clearInterval(time);
              this.code && console.warn('code', this.code);
              let message = this.getGuid()
              let web3 = new Web3(window.ethereum);
              web3.eth.personal.sign(message, this.address, async (err, res) => {
                if (res != null && res !== undefined && res !== '') {
                  this.$post({
                    url: "/login",
                    noToken: true,
                    loading: true,
                    data: {
                      account: this.address,
                      msg: message,
                      sign: res,
                      invite_code: this.code,
                    },
                    success: (res) => {
                      console.warn("登录成功");
                      this.session.set("token", res.data.token)
                      this.getdata();
                    },
                    tip: () => { },
                  });
                } else {
                  this.$toast.clear()
                  this.$dialog.alert({
                    message: '签名失败',
                  }).then(() => {
                    this.session.del("token")
                    window.location.reload();
                  });
                }
              })
            }
          }, 1000);
        }
        if (this.isToken) {
          this.getdata();
        }
      });
    },
    getdata() {
      this.$get({
        url: "/user/info",
        success: (res) => {
          this.info = res.data
        },
        tip: () => { },
      });
      this.$get({
        url: "/exchange_info",
        success: (res) => {
          this.cea_price = res.data.usdt_to_dichi
        },
        tip: () => { },
      });
      this.getlist()
    },
    getlist() {
      this.$get({
        loading: true,
        url: "/fold_line/lines",
        data: { type: Number(this.active) + 1 },
        success: (res) => {
          let ydata = []
          let xdata = []
          let data = res.data.reverse()
          data.forEach(item => {
            xdata.push(item.created_at.slice(0, 10))
            ydata.push(item.price)
          });
          this.$nextTick(() => {
            this.getinit(xdata, ydata)
          })
        },
        tip: () => { },
      });
    },
    change(index) {
      this.active = index
      this.getlist()
    },
    getinit(xdata, ydata) {
      var myChart = echarts.init(document.getElementById("echart"))
      myChart.setOption({
        tooltip: {
          trigger: "axis",
          axisPointer: { type: "shadow" },
        },
        grid: {
          left: "2%",
          right: "5%",
          top: "5%",
          bottom: "2%",
          containLabel: true
        },
        xAxis: [
          {
            type: "category",
            boundaryGap: false,
            data: xdata,
            axisLabel: {
              show: true,
              color: "#ffffff",
              formatter: (data) => {
                return data.slice(5, 10)
              }
            },
            axisLine: {
              color: "#ffffff"
            }
          },
        ],
        yAxis: [{
          type: "value",
          min: function (value) {
            return value.min;
          },
          axisLabel: {
            show: true,
            color: "#ffffff"
          },
          axisLine: {
            color: "#ffffff"
          }
        }],
        series: [
          {
            type: 'line',
            data: ydata,
            symbol: 'circle',
            symbolSize: 10,
            color: "#1AE8A0",
            smooth: true,
            areaStyle: {
              color: {
                type: 'linear',
                x: 0,
                y: 0,
                x2: 0,
                y2: 1,
                colorStops: [{
                  offset: 0, color: '#5A9170'
                }, {
                  offset: 1, color: '#131625'
                }],
                global: false // 缺省为 false
              }
            }
          },
        ],
      })
    },
  },
}
</script>
<style lang="less" scoped>
.indexbox {
  width: 100%;
  min-height: 100vh;
  background: url('../assets/img/bj@2x.png') no-repeat;
  background-size: 100% 100%;
  background-position: top center;
  background-attachment: fixed;
  .bodybox {
    width: 100%;
    padding: 0 15px 30px;
    .swipebox {
      width: 100%;
      border-radius: 10px;
      overflow: hidden;
      /deep/.van-swipe-item {
        width: 100%;
        height: 100%;
        >img,
        >video {
          width: 100%;
          height: 142px;
          object-fit: contain;
        }
      }
    }
    .newbox {
      width: 100%;
      padding: 8px 12px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      background: linear-gradient(328deg, #24809b 0%, #1B2230 93%, #1B1F2E 100%);
      border-radius: 6px 6px 6px 6px;
      border: 1px solid #3C4E60;
      margin: 15px 0;
      .imgl {
        width: 52px;
        height: 28px;
        margin-top: 4px;
        object-fit: contain;
      }
      .swipe {
        flex: 1;
        height: 6vw;
        .van-swipe-item {
          width: 100%;
          height: 8vw;
          padding: 0 8px;
          >p {
            width: 100%;
            line-height: 6vw;
            font-size: 14px;
            font-weight: 550;
          }
        }
      }
      .right {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        span {
          color: #92AFAB;
          font-size: 12px;
        }
        img {
          width: 10px;
          height: 10px;
        }
      }
    }
    .nums {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      >div {
        width: 30%;
        background: linear-gradient(32deg, #24809b 0%, #1B2230 93%, #1B1F2E 100%);
        border-radius: 6px 6px 6px 6px;
        border: 1px solid #3C4E60;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        padding: 12px;
        font-size: 12px;
        >p {
          &:nth-child(1) {
            font-size: 15px;
            font-weight: 550;
          }
        }
      }
    }
    .tits {
      width: 100%;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      padding: 15px 0;
      img {
        width: 22px;
        height: 22px;
      }
      span {
        font-size: 15px;
        margin-left: 10px;
      }
    }
    .echartbox {
      width: 100%;
      background: #110F20;
      border-radius: 6px 6px 6px 6px;
      border: 1px solid #3C4E60;
      padding: 16px 0;
      .t {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-bottom: 10px;
        span {
          font-size: 14px;
          &:nth-child(2) {
            font-weight: 550;
            color: #1AE8A0;
          }
        }
      }
      .tab {
        width: 100%;
        display: flex;
        justify-content: start;
        align-items: center;
        margin-bottom: 10px;
        >p {
          flex: 1;
          text-align: center;
          padding: 6px 6px 12px;
          background: linear-gradient(180deg, #24809b 0%, #1B2230 93%, #1B1F2E 100%);
          color: #1AE8A0;
          font-size: 14px;
        }
        .active {
          background: url('../assets/img/xz@2x.png') no-repeat;
          background-size: 100% 100%;
          color: #110F20;
          font-weight: 550;
        }
      }
      .ebox {
        width: 100%;
        height: 280px;
      }
    }
  }
}
//冰锥
.icce {
  width: 92vw;
  //height: 69vw;
  background: linear-gradient(328deg, #24809b 0%, #1B2230 93%, #1B1F2E 100%);
  border-radius: 3vw;
  margin: 0 auto;
  overflow: hidden;

  margin-bottom: 5vw;
  .ice {
    width: 92vw;
    height: 10vw;
    margin: 0 auto;
    border-radius: 3vw;

    img {
      width: 100%;
      height: 100%;
    }
  }
}
.wkf {
  width: 32vw;
  height: 32vw;
  margin: 0 auto;
  margin-top: 21vw;
  img {
    width: 100%;
    height: 100%;
  }
}
.wk {
  height: 6vw;
  font-size: 4vw;
  margin-top: 8vw;
  margin-bottom: 26vw;
  text-align: center;
  font-weight: bold;
  line-height: 6vw;
  color: #24809b;
}
</style>